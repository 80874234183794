import { CreateProjectServiceBody, InvoiceMethod } from "@simplicate/api-client";
import { format } from "date-fns";
import { transformToCreateServiceHourType } from "./transformHourTypes";
import { ValidProjectServiceForm } from "./useProjectServiceForm";

export const transformFormToCreateProjectServiceBody = (
  values: ValidProjectServiceForm,
  projectId: string,
): CreateProjectServiceBody => {
  const body = {
    projectIdentifier: projectId,
    defaultServiceIdentifier: values.defaultService,
    invoiceMethod: values.invoiceMethod,
    timeFrame: {
      startDate: values.timeframe.startDate ? format(values.timeframe.startDate, "yyyy-MM-dd") : null,
      endDate: values.timeframe.endDate ? format(values.timeframe.endDate, "yyyy-MM-dd") : null,
    },
    description: values.description,
    explanation: values.explanation,
    revenueGroupIdentifier: values.revenueGroup,
    vatCodeIdentifier: values.vatCode,
    hourTypes: values.hourTypes?.map(transformToCreateServiceHourType) ?? [],
    costTypes: [],
  };

  if (values.invoiceMethod === InvoiceMethod.fixed_price) {
    return {
      ...body,
      invoiceInInstallments: values.invoiceInInstallments ?? false,
      invoiceableFrom: values.invoiceableFrom ? format(values.invoiceableFrom, "yyyy-MM-dd") : null,
    };
  }

  return body;
};
